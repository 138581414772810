var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.customers.length === 1)?[(_vm.type === 'customer')?_c('router-link',{staticClass:"text-primary",attrs:{"to":{
        name: 'customers.individual',
        params: { id: _vm.customers[0]?.id },
      }}},[_vm._v(" "+_vm._s(_vm.customers[0]?.name))]):_c('p',[_vm._v(_vm._s(_vm.customers[0]?.name))]),(_vm.gardener)?_c('span',{staticClass:"is-block"},[_vm._v(_vm._s(_vm.gardener))]):_vm._e()]:[_c('el-popover',{attrs:{"placement":"bottom-end","trigger":"hover","width":"200"}},[_c('ul',_vm._l((_vm.customers),function(customer,i){return _c('li',{key:i},[(_vm.type === 'customer')?_c('router-link',{attrs:{"to":{
              name: 'customers.individual',
              params: { id: customer.id },
            }}},[_vm._v(" "+_vm._s(customer.name))]):_c('p',[_vm._v(_vm._s(customer.name))])],1)}),0),_c('p',{staticClass:"font-sm text-primary",attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(_vm.customers.length)+" "+_vm._s(_vm.type)+"s ")])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }