<template>
  <eden-container>
    <eden-table-actions
      :title="'Services Rendered Breakdown'"
      :show-search="false"
    >
      <template slot="actions">
        <eden-periods
          :default-period="'thisweek'"
          :custom-periods="periods"
          :show-custom-period="true"
          @set-period="getStats"
        />
      </template>
    </eden-table-actions>
    <el-table :data="computedServices" class="hoverable">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table :data="props.row.category_type_breakdown">
            <el-table-column>
              <template slot="header">
                <div class="table--header">
                  <span>Type</span>
                </div>
              </template>
              <template slot-scope="scope">
                <span>
                  {{ scope.row.category_type_name }}
                </span>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <div class="table--header">
                  <span>Quantity</span>
                </div>
              </template>
              <template slot-scope="scope">
                <span>
                  {{ scope.row.count }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Category</span>
          </div>
        </template>
        <template slot-scope="scope">
          <p>
            {{ scope.row.variation_name }}
          </p>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Quantity</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ scope.row.count }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </eden-container>
</template>

<script>
import dashboard from "@/requests/dashboard/beauty";
export default {
  name: "BeautyServicesRendered",
  data() {
    return {
      loading: false,
      services: [],
      defaultPeriod: "thismonth",
      periods: {
        lastmonth: "Last month",
        lastweek: "Last week",
        thisweek: "This week",
        thismonth: "This month",
      },
    };
  },
  computed: {
    computedServices() {
      return this.services.map((service) => {
        return {
          ...service,
        };
      });
    },
  },
  created() {
    this.getStats({ period: this.defaultPeriod });
  },
  methods: {
    getStats({ period, from, to }) {
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      dashboard
        .breakdown(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.services = data;
            this.defaultPeriod = period;
          }
          this.overview.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
