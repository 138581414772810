<template>
  <div class="issue">
    <span v-if="priority === 'high'" class="indicator"></span>
    <div class="title">
      <router-link :to="route"> {{ title }}</router-link>
      <span v-if="comments">
        <i class="el-icon-chat-round" /> {{ comments }}
      </span>
    </div>
    <p>{{ description }}</p>
    <span v-if="item" class="item">{{ item }}</span>
  </div>
</template>

<script>
export default {
  name: "IssueTitle",
  props: {
    id: {
      type: [String, Number],
      default: "",
      required: true,
    },
    title: {
      type: String,
      default: "",
      required: true,
    },
    description: {
      type: String,
      default: "",
      required: true,
    },
    item: {
      type: String,
      default: null,
    },
    comments: {
      type: Number,
      default: 0,
    },
    priority: {
      type: String,
      default: "",
      required: true,
    },
  },
  computed: {
    route() {
      return {
        name: "feedback.issues.issue",
        params: { id: this.id },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.issue {
  position: relative;
  padding-left: 15px;

  .indicator {
    position: absolute;
    left: 0;
    top: 5px;
    height: 6px;
    width: 6px;
    border-radius: 100px;
    background: var(--eden-red-primary);
  }

  .title {
    span {
      margin-left: 10px;
    }
  }
  a {
    color: var(--eden-green-primary);
    font-size: 0.825rem;
  }

  p {
    color: var(--eden-grey-secondary);
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 8px;
    font-size: 0.825rem;
  }

  .item {
    display: inline-block;
    margin-top: 8px;
    color: var(--eden-grey-primary);
    border: 1px solid var(--eden-grey-quaternary);
    border-radius: 8px;
    padding: 4px 8px;
    font-size: 0.75rem;
  }
}
</style>
