<template>
  <div>
    <el-row type="flex">
      <el-col :span="24">
        <eden-page-stats
          class="my-0"
          :loading="loading"
          :title="'Overview'"
          :stats="stats"
          :show-periods="true"
          :periods="periods"
          @set-period="getStats"
        />
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <dashboard-service-feedback :service="'beauty'" />
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <beauty-services-rendered />
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <beauty-issues-reported />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import DashboardServiceFeedback from "@/components/Dashboard/DashboardServiceFeedback";
import BeautyServicesRendered from "@/components/Dashboard/Beauty/BeautyServicesRendered";
import dashboard from "@/requests/dashboard/beauty";
import BeautyIssuesReported from '@/components/Dashboard/Beauty/BeautyIssuesReported.vue';

export default {
  name: "DashboardBeauty",
  components: {
    DashboardServiceFeedback,
    BeautyServicesRendered,
    BeautyIssuesReported,
  },
  data() {
    return {
      loading: false,
      overviewPeriod: "today",
      overview: {},
      periods: {
        today: "Today",
        lastweek: "Last week",
        thismonth: "This month",
      },
    };
  },
  computed: {
    stats() {
      const overview = this.overview;
      return [
        {
          label: "Total beauty subscribers",
          figure: this.formatFigure(overview.total_beauty_subscribers),
        },
        {
          label: "Subscribers served",
          figure: this.formatFigure(overview.total_subscribers_served),
        },
        {
          label: "One-time customers served",
          figure: this.formatFigure(overview.total_one_time_orders_served),
        },
        {
          label: "Total orders",
          figure: this.formatFigure(overview.total_orders),
        },
        {
          label: "One-time orders",
          figure: this.formatFigure(overview.total_one_time_orders),
        },
        {
          label: "Orders completed",
          figure: this.formatFigure(overview.total_orders_completed),
        },
        {
          label: "Standard beauty orders",
          figure: this.formatFigure(overview.total_standard_beauty_orders),
        },
        {
          label: "Premium beauty orders",
          figure: this.formatFigure(overview.total_premium_beauty_orders),
        },
      ];
    },
  },
  created() {
    this.getStats({ period: this.overviewPeriod });
  },
  methods: {
    getStats({ period, from, to }) {
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      dashboard
        .index(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.overview = data;
            this.overviewPeriod = period;
          }
          this.overview.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  &:not(:last-child) {
    margin-bottom: 50px !important;
  }
}
</style>
