import axios from "axios";

export default {
  index(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/beauty/overview?start_date=${start_date}&end_date=${end_date}`
    );
  },

  breakdown(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/beauty/rendered-service-breakdown?start_date=${start_date}&end_date=${end_date}`
    );
  },

  feedback(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/beauty/feedback-summary?start_date=${start_date}&end_date=${end_date}`
    );
  },

  issues(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/beauty/unresolved-issues?start_date=${start_date}&end_date=${end_date}`
    );
  },
};