import axios from "axios";

export default {
  summary(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/meal/overview?start_date=${start_date}&end_date=${end_date}`
    );
  },

  eventSummary(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/meal/event-summary?start_date=${start_date}&end_date=${end_date}`
    );
  },

  orderedCombo(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/meal/ordered-combo?start_date=${start_date}&end_date=${end_date}`
    );
  },

  pendingOrder() {
    return axios.get("crm/service-dashboard/meal/undispatched-orders");
  },

  // pendingGardenerOrder(id) {
  //   return axios.get(`crm/service-dashboard/meal/undispatched-orders/${id}`);
  // },
  
  complementaryOrder(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/meal/complementary-orders?start_date=${start_date}&end_date=${end_date}`
    );
  },

  feedback(type, start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/${type}/feedback-summary?start_date=${start_date}&end_date=${end_date}`
    );
  },

  issues(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/meal/unresolved-issues?start_date=${start_date}&end_date=${end_date}`
    );
  },
};