<template>
  <eden-container class="service-feedback">
    <div class="service-feedback--periods">
      <eden-periods
        :orientation="'horizontal'"
        :default-period="period"
        :custom-periods="periods"
        :show-custom-period="true"
        @set-period="getFeedback"
      />
    </div>
    <div class="service-feedback--ratings">
      <div v-for="(rating, index) in ratings" :key="index" class="rating">
        <img :src="getImage(`${rating.label}.svg`)" alt="Good" />
        <h6>{{ rating.value }}</h6>
        <router-link
          :to="{
            name: 'dashboard.feedback',
            query: { type: rating.label, period: period },
          }"
          >{{ formatText(rating.label) }} feedback</router-link
        >
      </div>
    </div>
  </eden-container>
</template>

<script>
import dashboard from "@/requests/dashboard/meal";

export default {
  name: "DashboardServiceFeedback",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    service: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      period: "thisweek",
      feedback: {},
      periods: {
        lastmonth: "Last month",
        lastweek: "Last week",
        thisweek: "This week",
        thismonth: "This month",
        custom: "Custom",
      },
    };
  },
  computed: {
    ratings() {
      return [
        {
          label: "positive",
          value: this.feedback.positive_feedback_count,
        },
        {
          label: "negative",
          value: this.feedback.negative_feedback_count,
        },
      ];
    },
  },
  mounted() {
    this.getFeedback({ period: this.period });
  },
  methods: {
    getFeedback({ period, from, to }) {
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      dashboard
        .feedback( this.service, start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.feedback = data;
            this.period = period;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.service-feedback {
  &--periods {
    display: flex;
    justify-content: center;
  }

  &--ratings {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;

    .rating {
      text-align: center;

      img {
        height: 48px;
        width: auto;
      }

      h6 {
        margin: 15px 0 8px;
      }

      a {
        color: var(--eden-green-primary);
        text-decoration: underline;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }
}
</style>
