<template>
  <div>
    <template v-if="customers.length === 1">
      <router-link
        v-if="type === 'customer'"
        class="text-primary"
        :to="{
          name: 'customers.individual',
          params: { id: customers[0]?.id },
        }"
      >
        {{ customers[0]?.name }}</router-link
      >
      <p v-else>{{ customers[0]?.name }}</p>
      <span v-if="gardener" class="is-block">{{ gardener }}</span>
    </template>
    <template v-else>
      <el-popover placement="bottom-end" trigger="hover" width="200">
        <ul>
          <li v-for="(customer, i) in customers" :key="i">
            <router-link
              v-if="type === 'customer'"
              :to="{
                name: 'customers.individual',
                params: { id: customer.id },
              }"
            >
              {{ customer.name }}</router-link
            >
            <p v-else>{{ customer.name }}</p>
          </li>
        </ul>
        <p slot="reference" class="font-sm text-primary">
          {{ customers.length }} {{ type }}s
        </p>
      </el-popover>
    </template>
  </div>
</template>

<script>
export default {
  name: "IssueCustomer",
  props: {
    type: {
      type: String,
      default: "",
      required: true,
    },
    customers: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
    gardener: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  display: block;
  font-size: 0.75rem;
  margin-top: 5px;
}

ul {
  list-style: square;

  li {
    a {
      color: var(--eden-green-primary);
    }

    &:not(:last-child) {
      margin-bottom: 10px !important;
    }
  }
}
</style>
